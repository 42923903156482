<template>
  <div class="loading">
    <img src="https://code.jquery.com/mobile/1.4.2/images/ajax-loader.gif" class="loading-image" /><span class="loading-text">LOADING...</span>
  </div>
</template>

<script>
export default {
  name: "Loading",
}
</script>

<style scoped>
.loading {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  display: block;
  opacity: 0.7;
  background-color: #fff;
  z-index: 99;
  text-align: center;
}
.loading-image {
  position: absolute;
  top: 300px;
  z-index: 100;
}
.loading-text {
  position: absolute;
  top: 370px;
  z-index: 100;
}
</style>
