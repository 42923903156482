<template>
  <div class="collapse show mt-2">
    <div class="card card-body" style="padding: 0">
      <div class="row">
        <social-sharing :url="myUrl" title="氣能康富" :description="socialDescription" :quote="socialDescription" inline-template network-tag="div">
          <div class="col-sm-6 col-7" style="text-align: right">
            <network network="line" class="d-inline">
              <img src="@/assets/img/icon/line-share.png" style="width: 115px; margin-right: 10px" />
            </network>
          </div>
        </social-sharing>
        <div class="col-sm-6 col-5" style="text-align: left">
          <button type="button" class="btn btn-outline-secondary" style="height: 52px" @click="shareUrl">複製連結</button>
        </div>
        <input type="hidden" id="copyArea" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ShareFriend",
  props: ["currentUrl"],
  data() {
    return {
      myUrl: "",
      socialDescription: "",
    }
  },
  methods: {
    shareUrl() {
      let copyElement = document.getElementById("copyArea")
      copyElement.setAttribute("type", "text")
      copyElement.value = this.myUrl

      navigator.clipboard
        .writeText(this.myUrl)
        .then(() => {
          return this.$Swal.fire({
            icon: "success",
            title: "已複製連結至剪貼簿",
            html: `請立馬分享給好友,連結內容如下：<br><div style="white-space:initial">${this.myUrl}</div>`,
          })
        })
        .catch(err => {
          console.error("Failed to copy: ", err)
        })

      copyElement.setAttribute("type", "hidden")
    },
  },
  mounted() {
    let parentUser = this.$store.state.userInfo.userProfile.userId
    this.myUrl = `${this.currentUrl}/${parentUser}`
  },
}
</script>

<style scoped></style>
