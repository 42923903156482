<template>
  <article>
    <Content>
      <div class="col-12" style="margin-bottom: 0 !important">
        <div class="card-header">
          <h4 v-show="currentViews > 0" style="margin: 0 0">已體驗人數：{{ currentViews }}</h4>
        </div>
      </div>
      <div class="col-md-6 col-sm-12">
        <img :src="leftPanelImgSrc" class="card-img-top" />
      </div>
      <div class="col-md-6 col-sm-12">
        <img :src="rightPanelImgSrc" class="card-img-top" />
        <div class="card-body">
          <div class="card-title">
            <Button
              :btnColor="'coffee'"
              :label="'領取體驗卷步驟說明'"
              class="btn-lg"
              :class="isSmallSize ? 'mx-5 mb-2' : 'mx-2'"
              @click="showModal"></Button>
            <Button :label="'領取'" class="mx-2 btn-lg" @click="interest"></Button>
            <Button :label="'分享好友'" class="mx-2 btn-lg" @click="shareFriend"></Button>
            <div style="color: red">{{ liffErrorMessage }}</div>
            <ShareFriend v-if="isLogin" :currentUrl="url"></ShareFriend>
          </div>
        </div>
      </div>
      <!-- 內容 -->
      <div class="col-12">
        <div style="text-align: center">
          <div v-for="(item, index) in mediaList" :key="index">
            <img v-show="item.imageUrl" class="card-img-top mx-auto d-block" :src="item.imageUrl" />
            <youtube v-if="item.videoId" :video-id="item.videoId" :fitParent="true"></youtube>
          </div>
        </div>
      </div>
    </Content>
    <Modal :id="'modal_step'">
      <span slot="header"><div style="font-size: 1.7rem">領取體驗卷步驟說明</div></span>
      <img class="mx-auto d-block" style="width: 100%" src="@/assets/img/advertisement/step.jpg" />
    </Modal>
  </article>
</template>

<script>
import Button from "@/components/style/Button"
import Modal from "@/components/style/Modal"
import ShareFriend from "@/components/style/ShareFriend"
import Content from "@/components/style/Content"

import Cookies from "js-cookie"
import liff from "@line/liff"
export default {
  name: "Advertisement",
  metaInfo() {
    return {
      title: "免費體驗卷",
    }
  },
  components: {
    Button,
    Modal,
    ShareFriend,
    Content,
  },
  data() {
    return {
      currentViews: 0,
      mediaList: [],
      leftPanelImgSrc: null,
      rightPanelImgSrc: null,
      isSmallSize: false,
      liffErrorMessage: null,
    }
  },
  methods: {
    async interest() {
      try {
        if (!this.isLogin) {
          this.$store.dispatch("checkLogin")
          return
        }

        let response = await this.$http.post("/user/useFreeTicket/new")
        if (response.data.errorMsg) {
          return this.$Swal.fire({ icon: "error", title: "異常訊息", text: `${response.data.errorMsg}` })
        }

        await this.$Swal.fire({ icon: "success", title: "成功訊息", text: "領取體驗卷成功" })
        await this.$store.dispatch("searchMember")
        this.$router.push("/user")
      } catch (error) {
        return this.$Swal.fire({ icon: "error", title: "系統發生錯誤", text: String(error) })
      }
    },
    showModal() {
      $("#modal_step").modal("show")
    },
    async shareFriend() {
      this.liffErrorMessage = null
      try {
        // let shareUserId = Cookies.get("shareUserId")
        // if (!shareUserId) {
        //   if (!this.$store.getters.getToken()) {
        //     this.$store.dispatch("checkLogin")
        //     return
        //   } else {
        //     await this.$store.dispatch("searchMember")
        //   }
        // }
        if (!this.$store.getters.getToken()) {
          this.$store.dispatch("checkLogin")
          return
        } else {
          await this.$store.dispatch("searchMember")
        }
        // this.liffErrorMessage = "prepare init"
        // await liff.init({ liffId: this.liff_id })
        // this.liffErrorMessage = "done init"
        // if (!liff.isLoggedIn()) {
        //   this.liffErrorMessage = "prepare login"
        //   liff.login()
        // }
        // this.liffErrorMessage = "check share"
        // if (liff.isApiAvailable("shareTargetPicker")) {
        //   this.liffErrorMessage = "prepare share"
        //   await liff.shareTargetPicker([
        //     {
        //       type: "flex",
        //       altText: "氣能康富分享",
        //       contents: {
        //         type: "bubble",
        //         hero: {
        //           type: "image",
        //           url: this.liff_image_url,
        //           size: "full",
        //         },
        //         footer: {
        //           type: "box",
        //           layout: "horizontal",
        //           spacing: "md",
        //           contents: [
        //             {
        //               type: "box",
        //               layout: "horizontal",
        //               contents: [
        //                 {
        //                   type: "button",
        //                   action: {
        //                     type: "uri",
        //                     label: "分享免費體驗券",
        //                     uri: this.shareUrl,
        //                   },
        //                   style: "primary",
        //                   color: "#B8860B",
        //                   adjustMode: "shrink-to-fit",
        //                 },
        //               ],
        //             },
        //             {
        //               type: "box",
        //               layout: "horizontal",
        //               contents: [
        //                 {
        //                   type: "button",
        //                   action: {
        //                     type: "uri",
        //                     label: "領取免費體驗券",
        //                     uri: this.shareUrl,
        //                   },
        //                   style: "primary",
        //                   color: "#8A2BE2",
        //                   adjustMode: "shrink-to-fit",
        //                 },
        //               ],
        //             },
        //           ],
        //           height: "80px",
        //         },
        //       },
        //     },
        //   ])
        // } else {
        //   throw new Error(`Line Liff not supported, version is ${liff.getLineVersion()}`)
        // }
      } catch (error) {
        this.liffErrorMessage = `line liff error: ${String(error)}`
      }
    },
  },
  async mounted() {
    try {
      this.isSmallSize = $(window).width() < 992

      this.$store.commit("SET_LOADING", true)
      let promise1 = this.$http.get("/system/getCurrentViews")
      let promise2 = this.$http.get("/system/getBlobList?blobType=freeTicketBlob")
      let promiseArr = await Promise.all([promise1, promise2])
      this.$store.commit("SET_LOADING", false)

      for (let i = 0; i < 2; i++) {
        let result = promiseArr[i].data
        if (result.errorMsg) {
          console.log(result.errorMsg)
          return
        }

        if (i == 0) {
          this.currentViews = result
        } else {
          this.mediaList = _.sortBy(
            result.filter(item => item.sortNo > 2),
            [
              o => {
                return parseInt(o.sortNo)
              },
            ]
          )

          this.mediaList.forEach(item => {
            item.imageUrl = null
            if (!item.videoId) {
              let imageUrl = item.blobUrl
              if (this.isSmallSize && item.blobUrlSmall) {
                imageUrl = item.blobUrlSmall
              }
              item.imageUrl = imageUrl
            }
          })
          this.leftPanelImgSrc = this.isSmallSize ? result[0].blobUrlSmall || result[0].blobUrl : result[0].blobUrl
          this.rightPanelImgSrc = this.isSmallSize ? result[1].blobUrlSmall || result[1].blobUrl : result[1].blobUrl
        }
      }
    } catch (error) {
      this.$store.commit("SET_LOADING", false)
      console.log(error)
    }
  },
  computed: {
    shareUrl() {
      return `https://liff.line.me/${this.liff_id}/${this.parentUser}`
    },
    parentUser() {
      let shareUserId = Cookies.get("shareUserId")
      if (this.isLogin) {
        return this.$store.state.userInfo.userProfile.userId
      } else if (shareUserId) {
        return shareUserId
      } else {
        return ""
      }
    },
    isLogin() {
      return this.$store.getters.isLogin()
    },
    url() {
      return `${location.protocol}//${location.host}${this.$route.path}`
    },
  },
}
</script>

<style scoped>
.card-body,
.card-header {
  text-align: center;
}
.card-header h3 {
  margin: 0;
  color: crimson;
}
.card-header {
  padding: 0.25rem 1.25rem;
}
.card-body {
  padding: 0;
}
.container .col-md-4 {
  margin-bottom: 0 !important;
}
</style>
