<template>
  <footer class="footer-section">
    <div class="footer-bottom pt-4 pb-4">
      <div class="container">
        <div class="row text-center justify-content-center">
          <div class="col-md-6 col-lg-5">
            <p class="copyright-text pb-0 mb-0">
              Copyrights © 2020. All rights reserved by 氣能康富
            </p>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
}
</script>

<style scoped>
footer {
  /*   background: url("~@/assets/img/footer-bg.png") no-repeat center top / cover; */
  background-color: #222;
}
.footer-bottom {
  border: 1px solid #404040;
  border-width: 1px 0;
}
</style>
