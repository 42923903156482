<template>
  <article>
    <Content v-show="isLogin">
      <div class="col-12">
        <div class="card-header">
          <div class="alert alert-info" role="alert">會員中心</div>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-sm-6 col-12">
              <div class="form-group row">
                <label class="col-sm-3 col-form-label col-form-label-lg">USER ID</label>
                <div class="col-sm-9">
                  <InputText v-model="model.userId" :disabled="true" />
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-12">
              <div class="form-group row">
                <label class="col-sm-3 col-form-label col-form-label-lg">LINE名稱</label>
                <div class="col-sm-9">
                  <InputText v-model="model.lineName" :disabled="true" />
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6 col-12">
              <div class="form-group row">
                <label class="col-sm-3 col-form-label col-form-label-lg">會員名稱</label>
                <div class="col-sm-9">
                  <InputText v-model="model.userName" :disabled="true" />
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-12">
              <div class="form-group row">
                <label class="col-sm-3 col-form-label col-form-label-lg">當前會籍</label>
                <div class="col-sm-9">
                  <InputText v-model="membershipDateStr" :disabled="true" />
                </div>
              </div>
            </div>
          </div>
          <div class="row" v-show="isAgent">
            <div class="col-sm-6 col-12">
              <div class="form-group row">
                <label class="col-sm-3 col-form-label col-form-label-lg">身分證號碼</label>
                <div class="col-sm-9">
                  <InputText v-model="model.idNumber" :maxlength="10" />
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-12">
              <div class="form-group row">
                <label class="col-sm-3 col-form-label col-form-label-lg">實名認證</label>
                <div class="col-sm-9">
                  <InputText v-model="realConfirmStr" :disabled="true" />
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6 col-12">
              <div class="form-group row">
                <label class="col-sm-3 col-form-label col-form-label-lg">手機號碼</label>
                <div class="col-sm-9">
                  <InputText v-model="model.phoneNumber" :maxlength="10" />
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-12">
              <div class="form-group row">
                <label class="col-sm-3 col-form-label col-form-label-lg">地址</label>
                <div class="col-sm-9">
                  <InputText v-model="model.address" />
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6 col-12" v-show="model.freeTicket == '尚未使用'">
              <div class="form-group row">
                <label class="col-sm-3 col-form-label col-form-label-lg">體驗卷店家</label>
                <div class="col-sm-9">
                  <select class="custom-select custom-select-lg mb-3" v-model="model.freeTicketStoreId" @change="changeFreeTicketStore">
                    <option :value="null">請選擇體驗卷店家</option>
                    <option v-for="(item, index) in storeList" :value="item.storeId" :key="index">
                      {{ item.storeName }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-12">
              <div class="form-group row">
                <label class="col-sm-3 col-form-label col-form-label-lg">免費體驗卷</label>
                <div class="col-sm-5">
                  <InputText v-model="model.freeTicket" :disabled="true" />
                </div>
                <div class="col-sm-4">
                  <Button v-if="model.freeTicket == '尚未使用'" :label="'使用體驗卷'" :btnColor="'red'" class="mt-1" @click="useFreeTicket"></Button>
                </div>
              </div>
            </div>
          </div>
          <div class="row" v-show="model.freeTicket == '尚未使用'">
            <div class="col-sm-6 col-12">
              <div class="form-group row">
                <label class="col-sm-3 col-form-label col-form-label-lg">體驗卷服務人員</label>
                <div class="col-sm-9">
                  <select class="custom-select custom-select-lg mb-3" v-model="storeStaff">
                    <option :value="null">請選擇體驗卷服務人員</option>
                    <option v-for="(item, index) in storeStaffList" :value="item.staffName" :key="index">
                      {{ item.staffName }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="row" v-show="isAgent">
            <div class="col-sm-6 col-12">
              <div class="form-group row" style="margin-bottom: 0">
                <label class="col-sm-3 col-form-label col-form-label-lg">提領店家</label>
                <div class="col-sm-9">
                  <select class="custom-select custom-select-lg mb-3" v-model="model.withdrawStore">
                    <option :value="null">請選擇提領店家</option>
                    <option v-for="(item, index) in storeList" :value="item.storeId" :key="index">
                      {{ item.storeName }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="alert alert-info" role="alert" style="text-align: center; padding: 0; font-size: 1.2rem">
                改選提領點數地點，請點下方賴留言。
              </div>
            </div>
            <div v-if="model.withdrawStore == 'store888'" class="col-sm-6 col-12">
              <div class="form-group row">
                <label class="col-sm-3 col-form-label col-form-label-lg">提領點數帳號</label>
                <div class="col-sm-9">
                  <textarea
                    v-model="model.withdrawAccount"
                    class="form-control"
                    placeholder="1、銀行代碼 - 帳號
2、台灣支付帳號
3、街口支付帳號
4、LINE PAY帳號"
                    rows="4"
                    cols="45"></textarea>
                </div>
              </div>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-md-3">
              <Button :label="'確認修改資料'" :btnColor="'yellow'" @click="updateMember"></Button>
            </div>
            <div class="col-md-3">
              <Button :label="'開啟QR CODE'" @click="openQRcode"></Button>
            </div>
            <div class="col-md-3" v-show="isAgentOrVIP">
              <Button :label="'查看點數明細'" :btnColor="'blue'" @click="goBonusPage"></Button>
            </div>
            <div class="col-md-3" v-show="showContract">
              <Button :label="'簽署特專合約'" :btnColor="'red'" @click="displayContract"></Button>
            </div>
          </div>
        </div>
        <!-- 票卷清單 -->
        <div v-if="ticketList.length > 0" class="alert alert-success" role="alert" style="margin-bottom: 0">票卷清單</div>
        <div v-if="ticketList.length > 0" style="height: 400px; overflow-y: auto">
          <table class="table table-hover">
            <thead style="font-weight: bold">
              <tr>
                <th scope="col">票卷名稱</th>
                <th scope="col">庫存數量</th>
                <th scope="col">店家名稱</th>
                <th scope="col">使用</th>
              </tr>
            </thead>
            <tbody>
              <tr :key="index" v-for="(item, index) in ticketList">
                <td style="word-break: break-all; width: 40%">{{ item.productName.split("@")[0] }}<br />{{ item.productName.split("@")[1] }}</td>
                <td>{{ item.availableAmount }}</td>
                <td>{{ item.storeId }}</td>
                <td style="width: 30%">
                  <Button :label="'使用'" @click="useProductTicket(item)"></Button>
                  <select
                    v-if="item.productNumber == 'pd005-14' || item.productNumber == 'pd005-15'"
                    v-model="item.useAmount"
                    class="custom-select custom-select-lg mt-1">
                    <option :value="null">請選擇VIP票券使用數量</option>
                    <option :value="4">4張</option>
                    <option :value="6">6張</option>
                    <option :value="8">8張</option>
                  </select>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- 客戶清單 -->
        <div class="alert alert-success mt-5" role="alert" style="margin-bottom: 0">客戶清單</div>
        <div style="height: 400px; overflow-y: auto">
          <table class="table table-hover">
            <thead style="font-weight: bold">
              <tr>
                <th scope="col">序號</th>
                <th scope="col">客戶之LINE名稱</th>
                <th scope="col">客戶型態</th>
              </tr>
            </thead>
            <tbody v-if="childList.length > 0">
              <tr :key="index" v-for="(item, index) in childList" :class="item.agentType == '介紹' ? 'table-warning' : 'table-success'">
                <td>{{ index + 1 }}</td>
                <td style="word-break: break-all; width: 50%">
                  {{ item.lineName }}
                </td>
                <td>{{ item.agentType }}</td>
              </tr>
            </tbody>
            <tbody v-else>
              <td colspan="3">無客戶清單</td>
            </tbody>
          </table>
        </div>
        <!-- 特專清單 -->
        <div v-if="isAgent" class="alert alert-danger mt-5" role="alert" style="margin-bottom: 0">特專清單</div>
        <div v-if="isAgent" style="height: 400px; overflow-y: auto">
          <table class="table table-hover">
            <thead style="font-weight: bold">
              <tr>
                <th scope="col">序號</th>
                <th scope="col">特專之LINE名稱</th>
                <th scope="col">特專型態</th>
              </tr>
            </thead>
            <tbody v-if="agentChildList.length > 0">
              <tr :key="index" v-for="(item, index) in agentChildList" :class="item.agentType == '介紹' ? 'table-warning' : 'table-success'">
                <td>{{ index + 1 }}</td>
                <td style="word-break: break-all; width: 50%">
                  {{ item.lineName }}
                </td>
                <td>{{ item.agentType }}</td>
              </tr>
            </tbody>
            <tbody v-else>
              <td colspan="3">無特專清單</td>
            </tbody>
          </table>
        </div>
      </div>
    </Content>
    <Modal :id="'modal_qrcode'">
      <span slot="header">我的QR CODE</span>
      <div>
        <canvas ref="canvas" class="d-block mx-auto"></canvas>
      </div>
    </Modal>
    <!-- 契約內容 -->
    <Modal :id="'modal_contract'">
      <span slot="header">
        <div style="font-size: 1.7rem">特專合作協議書</div>
      </span>
      <span slot="footer">
        <button v-show="agreeContract" @click="signContract" type="button" class="btn btn-lg btn-success mx-1" data-dismiss="modal">同意送出</button>
      </span>
      <div class="card-body">
        <p class="card-text ml-2">
          與康沅股份有限公司 (氣能康富健康中心)業務合作同意約定如下<br />
          康沅股份有限公司 (氣能康富健康中心) : 以下稱甲方<br />
          同意合作協議書立約人，即特專 : 以下稱乙方<br />
          <br />
          第一條 特專(乙方)之權利義務<br />
          一、乙方享有下兩層特專或客戶消費之分潤，分潤金額依照氣能康富健康中心系統設定。說明如下:<br />
          1.乙方分享電子或紙本免費體驗劵給客戶，客戶消費則乙方有介紹分潤。<br />
          2.乙方引薦之下層特專或客戶，再引薦客戶消費，乙方有推廣分潤。<br />
          3.乙方可分享使用心得，無需銷售、說明產品。<br />
          4.乙方佣金可到店家提領，或由甲方匯款至乙方之銀行帳號中。<br />
          5.第一次提領獎金時，稅務申報所需，乙方若是個人須提供身份證影印本，乙方若是行號、公司法人須提供發票給甲方。<br />
          二、 乙方權利享下兩層特專及客戶消費之佣金收入，權利不得轉讓。<br />
          但可繼承，乙方須提出申請，若乙方為無行為能力人，子女或父母可提出申請。<br />
          三、乙方的父母、子女、夫妻平行掛上層股東或特專下方。<br />
          四、若氣能康富健康中心有釋股，或之後新成立店家開放認股，經過甲方經營團隊評選之特專，可認股。<br />
          <br />
          第二條 特專(乙方)注意事項<br />
          一、乙方非甲方之代理人、僱員、合夥人或合營者。不得聲稱與公司有此等關係。<br />
          二、乙方不得用甲方客戶資源發展其他品牌事業項目、組織下線(自己下層第一代特專或客戶例外)，否則甲方與乙方無條件解約，乙方不得有任何異議。<br />
          三、乙方不得銷售公司客戶非本公司商品。(自己下層第一代特專或客戶除外)，否則甲方與乙方無條件解約，乙方不得有任何異議。<br />
          四、乙方有不利於或有害甲方名聲，甲方即取消乙方特專及分潤資格。<br />
          <br />
          第三條 氣能康富健康中心(甲方)之權利義務<br />
          一、甲方提供產品有氣功墊、活力機、能量環、強膝帶、能量杯墊、店內使用設備票劵、會員使用設備，及未來新增品項供乙方引薦之客戶體驗及消費。<br />
          二、乙方可隨時申請提領佣金收入，甲方將於隔月10號前發放，其匯款手續費15元由乙方支付。<br />
          三、甲方保有修改特專合作協議書內容權利，但不朔及既往。<br />
          <br />
          第四條 違反本契約發生糾紛而訴訟時， 以台灣新竹地方法院為管轄法院。
        </p>
        <Checkbox :id="'checkContractId'" :label="'同意請打勾，即日起生效。'" @input="checkContract" />
      </div>
    </Modal>
  </article>
</template>

<script>
import InputText from "@/components/style/InputText"
import Button from "@/components/style/Button"
import Modal from "@/components/style/Modal"
import Content from "@/components/style/Content"
import Checkbox from "@/components/style/Checkbox"

import "lodash"
import QRCode from "qrcode"
import Cookies from "js-cookie"
export default {
  name: "User",
  components: {
    InputText,
    Button,
    Modal,
    Content,
    Checkbox,
  },
  data() {
    return {
      idNumberRegex: /^[A-Z][12]\d{8}$/,
      storeList: [],
      model: {
        userId: null,
        lineName: null,
        phoneNumber: null,
        userName: null,
        idNumber: null,
        withdrawStore: null,
        address: null,
        freeTicket: null,
        freeTicketStoreId: null,
        membershipDate: null,
        realConfirm: null,
        withdrawAccount: null,
      },
      childList: [],
      agentChildList: [],
      ticketList: [],
      storeStaffList: [],
      storeStaff: null,
      agreeContract: false,
    }
  },
  methods: {
    async updateMember() {
      if (this.model.withdrawStore == "store888" && !this.model.withdrawAccount) {
        return this.$Swal.fire({ icon: "error", title: "異常訊息", text: "請輸入提領點數帳號" })
      }
      if (this.isAgent && this.model.idNumber) {
        this.model.idNumber = this.model.idNumber.toUpperCase()
        if (!this.idNumberRegex.test(this.model.idNumber)) {
          return this.$Swal.fire({ icon: "error", title: "異常訊息", text: "身分證號碼格式錯誤" })
        }
      }

      let obj = {}
      obj.phoneNumber = this.model.phoneNumber
      obj.withdrawStore = this.model.withdrawStore
      obj.address = this.model.address
      obj.withdrawAccount = this.model.withdrawStore == "store888" ? this.model.withdrawAccount : null
      obj.idNumber = this.model.idNumber

      try {
        let response = await this.$http.post("/user/updateMember", obj)
        if (response.data.errorMsg) {
          return this.$Swal.fire({ icon: "error", title: "異常訊息", text: `${response.data.errorMsg}` })
        }

        await this.$Swal.fire({ icon: "success", title: "成功訊息", text: "修改會員資料完成" })
        this.$store.dispatch("updateToken", response.data)
        this.$store.dispatch("searchMember")
      } catch (error) {
        return this.$Swal.fire({ icon: "error", title: "系統發生錯誤", text: String(error) })
      }
    },
    async useFreeTicket() {
      if (!this.model.freeTicketStoreId) {
        return this.$Swal.fire({ icon: "error", title: "異常訊息", text: "請選擇體驗卷店家" })
      } else if (!this.storeStaff) {
        return this.$Swal.fire({ icon: "error", title: "異常訊息", text: "請選擇體驗卷服務人員" })
      }

      try {
        let alert = await this.$Swal.fire({
          title: "請輸入店家密碼",
          input: "text",
          showCancelButton: true,
          confirmButtonText: "送出",
          allowOutsideClick: false,
        })
        if (!alert.value) {
          return
        }

        let body = {}
        body.account = this.model.freeTicketStoreId
        body.password = alert.value
        body.storeStaff = this.storeStaff
        let response = await this.$http.post(`/user/useFreeTicket/used`, body)
        if (response.data.errorMsg) {
          return this.$Swal.fire({ icon: "error", title: "異常訊息", text: `${response.data.errorMsg}` })
        }

        await this.$Swal.fire({ icon: "success", title: "成功訊息", text: "使用體驗卷完成" })
        this.model.freeTicket = "已使用"
        this.$store.dispatch("searchMember")
      } catch (error) {
        return this.$Swal.fire({ icon: "error", title: "系統發生錯誤", text: String(error) })
      }
    },
    async useProductTicket(item) {
      try {
        if (item.productNumber == "pd005-14" || item.productNumber == "pd005-15") {
          if (!item.useAmount) {
            return this.$Swal.fire({ icon: "error", title: "異常訊息", text: "請選擇VIP票券使用數量" })
          }
        }

        let useAmountStr = item.productNumber == "pd005-14" || item.productNumber == "pd005-15" ? String(item.useAmount) : "乙"
        let availableAmountStr =
          item.productNumber == "pd005-14" || item.productNumber == "pd005-15"
            ? String(item.availableAmount - item.useAmount)
            : String(item.availableAmount - 1)

        let confirmed = await this.$Swal.fire({
          icon: "info",
          title: "確認訊息",
          text: `本次使用 ${item.productName.split("@")[0]} ${useAmountStr}張，剩下${availableAmountStr}張`,
          showCancelButton: true,
          confirmButtonText: "確認使用",
          cancelButtonText: "取消",
        })
        if (!confirmed.value) {
          return
        }

        let body = {}
        body.productNumber = item.productNumber
        body.detailAmount = !item.useAmount ? 0 : item.useAmount
        let response = await this.$http.post(`/user/useProductTicket/${item.orderNumber}`, body)
        if (response.data.errorMsg) {
          return this.$Swal.fire({ icon: "error", title: "異常訊息", text: `${response.data.errorMsg}` })
        }

        this.$store.commit("MINUS_AMOUNT_TICKETLIST", item)
        this.ticketList = this.$store.state.userInfo.ticketList
        this.initTicketList()
      } catch (error) {
        return this.$Swal.fire({ icon: "error", title: "系統發生錯誤", text: String(error) })
      }
    },
    initTicketList() {
      this.ticketList.forEach(item => {
        item.productName = this.constTicketList.find(detail => detail.productNumber == item.productNumber).productName
        item.useAmount = null
      })
    },
    openQRcode() {
      $("#modal_qrcode").modal("show")
    },
    goBonusPage() {
      this.$router.push({ path: "/bonus" })
    },
    async changeFreeTicketStore() {
      if (!this.model.freeTicketStoreId) {
        return
      }

      this.storeStaff = null
      let response = await this.$http.get(`/store/getStockStaff/${this.model.freeTicketStoreId}`)
      if (response.data.errorMsg) {
        return this.$Swal.fire({ icon: "error", title: "異常訊息", text: `${response.data.errorMsg}` })
      }
      this.storeStaffList = response.data
    },
    displayContract() {
      this.agreeContract = false
      $("#checkContractId").prop("checked", false)
      $("#modal_contract").modal("show")
    },
    checkContract(payload) {
      this.agreeContract = payload.checked
    },
    async signContract() {
      try {
        let response = await this.$http.post("/user/signContract")
        if (response.data.errorMsg) {
          return this.$Swal.fire({ icon: "error", title: "異常訊息", text: `${response.data.errorMsg}` })
        }

        Cookies.remove("showContract")

        let html = "簽署完成，請記得<br>1. 輸入身分證號碼並完成實名認證<br>2. 點選提領店家<br>3. 輸入手機號碼<br>否者無法結算點數"
        await this.$Swal.fire({ icon: "success", title: "成功訊息", html })
        this.$store.dispatch("updateToken", response.data)
        this.$store.dispatch("searchMember")
      } catch (error) {
        return this.$Swal.fire({ icon: "error", title: "系統發生錯誤", text: String(error) })
      }
    },
  },
  computed: {
    membershipDateStr() {
      let membershipDate = ""
      if (this.model.membershipDate) {
        membershipDate = `${this.model.membershipDate.split("-")[0]} 年 ${this.model.membershipDate.split("-")[1]} 月`
      }
      return membershipDate
    },
    realConfirmStr() {
      let realConfirm = ""
      if (this.model.realConfirm) {
        realConfirm = this.model.realConfirm == "Y" ? "完成" : "尚未"
      }
      return realConfirm
    },
    isAgent() {
      return this.$store.state.userInfo.userProfile.agent == "Y"
    },
    isLogin() {
      return this.$store.getters.isLogin()
    },
    isAgentOrVIP() {
      return this.$store.state.userInfo.userProfile.agent == "Y" || this.$store.state.userInfo.userProfile.vipFlag == "Y"
    },
    showContract() {
      if (this.$store.state.userInfo.userProfile.agent == "Y") {
        Cookies.remove("showContract")
        return false
      }

      let userId = this.$store.state.userInfo.userProfile.userId
      if (Cookies.get("showContract") && Cookies.get("showContract") == userId) {
        return true
      } else {
        return false
      }
    },
  },
  async mounted() {
    try {
      if (!this.isLogin) {
        this.$store.dispatch("checkLogin")
        return
      }

      this.model = Object.assign(
        {
          userId: null,
          lineName: null,
          phoneNumber: null,
          userName: null,
          idNumber: null,
          withdrawStore: null,
          address: null,
          freeTicket: null,
          freeTicketStoreId: null,
          membershipDate: null,
          realConfirm: null,
          withdrawAccount: null,
        },
        this.$store.state.userInfo.userProfile
      )

      if (this.model.freeTicket == "Y") {
        this.model.freeTicket = "已使用"
      } else if (this.model.freeTicket == "N") {
        this.model.freeTicket = "尚未使用"
      } else if (this.model.freeTicket == "nothing") {
        this.model.freeTicket = "尚未領取"
      }

      this.childList = this.$store.state.userInfo.childList.filter(item => item.agent == "N")
      this.childList = _.sortBy(this.childList, "sortNo")

      this.agentChildList = this.$store.state.userInfo.childList.filter(item => item.agent == "Y")
      this.agentChildList = _.sortBy(this.agentChildList, "sortNo")

      this.ticketList = this.$store.state.userInfo.ticketList
      this.initTicketList()

      QRCode.toCanvas(this.$refs.canvas, `${location.origin}/advertisement/${this.model.userId}`)

      let response = await this.$http.get("/store/searchList?enable=Y")
      if (response.data.errorMsg) {
        return this.$Swal.fire({ icon: "error", title: "異常訊息", text: `${response.data.errorMsg}` })
      }

      this.storeList = _.sortBy(response.data, [
        o => {
          return parseInt(o.sortNo)
        },
      ])
    } catch (error) {
      return this.$Swal.fire({ icon: "error", title: "系統發生錯誤", text: String(error) })
    }
  },
}
</script>

<style scoped>
.card-header {
  text-align: center;
  padding: 0;
}
.card-header div {
  margin-bottom: 0;
  font-size: 1.5rem;
  font-weight: bold;
}
thead,
tbody,
.alert {
  text-align: center;
  font-size: 1.5rem;
}
.justify-content-center div {
  text-align: center;
}
textarea {
  background: #fdfcfb;
  font-family: inherit;
  color: #737373;
  letter-spacing: 1px;
}
#modal_contract .card-title {
  font-size: 1.6rem;
  font-weight: bold;
  color: black;
}
#modal_contract .card-body {
  font-size: 1.5rem;
}
</style>
