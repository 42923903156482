<template>
  <section class="bottom-icon row">
    <a href="tel:0922047781" id="phoneIcon"></a>
    <a href="javascript:void(0)" id="lineIcon" @click="contactLine"></a>
    <a href="javascript:void(0)" id="telegramIcon" @click="contactTelegram"></a>
  </section>
</template>

<script>
export default {
  name: "BottomIcon",
  methods: {
    contactLine() {
      location.href = this.line_group_link
    },
    contactTelegram() {
      location.href = "https://t.me/jomze"
    },
  },
}
</script>

<style scoped>
section a {
  display: inline;
}
section {
  margin-right: 0px;
  margin-left: 0px;
}
</style>
