<template>
  <article>
    <Content style="background-color: #fffafa" class="product-container">
      <div class="col-md-6 col-sm-12">
        <img :src="imgSrc" class="card-img-top" />
      </div>
      <div class="col-md-6 col-sm-12">
        <div class="card-body">
          <div class="card-title">
            <!-- 租用 -->
            <div class="col-12" v-if="current == 'item1' || current == 'item3'">
              <div class="form-group row">
                <label class="col-3 col-form-label col-form-label-lg">{{ current == "item1" ? "租/買" : "租用" }}</label>
                <div class="col-9">
                  <select class="custom-select custom-select-lg mb-1" v-model="productType" @change="changeProductType">
                    <option v-for="(item, index) in productTypeList" :value="item.value" :key="index">
                      {{ item.label }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <!-- 售價 -->
            <div class="col-12" v-if="current != 'item5'">
              <div class="form-group row">
                <label class="col-3 col-form-label col-form-label-lg">{{
                  model.title && model.title.indexOf("租") > -1 ? (model.title.indexOf("租轉買") > -1 ? "租轉買價格" : "租用價格") : "售價"
                }}</label>
                <div class="col-9">
                  <InputText v-model="model.price" :disabled="true" />
                </div>
                <!-- 額外備註 -->
                <div class="col-12">
                  <div style="font-weight: bold">
                    <span v-if="current == 'item1' && productType == '0'"> (刷卡請點下方賴詢問刷卡連結。) </span>
                    <span v-else-if="current == 'item1' && productType == '2'"> (租期內轉買扣抵2000元。) </span>
                  </div>
                </div>
              </div>
            </div>
            <!-- 運費 -->
            <div class="col-12" v-if="showAgent">
              <div class="form-group row">
                <label class="col-3 col-form-label col-form-label-lg">運費</label>
                <div class="col-9">
                  <InputText v-model="model.fare" :disabled="true" />
                </div>
                <!-- 額外備註 -->
                <div class="col-12">
                  <div style="font-weight: bold">
                    <span v-if="current == 'item6'"> (四個以上免運。)<br /> </span>
                  </div>
                </div>
              </div>
            </div>
            <!-- 數量 -->
            <div class="col-12" v-if="current != 'item5'">
              <div class="form-group row">
                <label class="col-3 col-form-label col-form-label-lg">數量</label>
                <div class="col-9">
                  <select class="custom-select custom-select-lg mb-1" v-model="model.amount" :disabled="isMember">
                    <option v-for="(item, index) in amountList" :value="item.value" :key="index">
                      {{ item.label }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <!-- 票券 -->
            <div class="col-12 mb-3" v-if="current == 'item5'">
              <ul class="list-group">
                <li class="list-group-item" v-for="(item, index) in model.ticketList" :key="index">
                  <div class="row">
                    <div class="col-md-4 col-sm-12">
                      {{ item.productName.split("@")[0] }}<br />{{ item.productName.split("@")[1] }} ＄{{ item.price }}
                    </div>
                    <div class="col-md-8 col-sm-12">
                      <div class="form-group row">
                        <label class="col-4 col-form-label col-form-label-lg">數量</label>
                        <div class="col-8">
                          <select class="custom-select custom-select-lg mb-3" v-model="item.amount">
                            <option v-for="(amt, amt_index) in amountList" :value="amt.value" :key="amt_index">
                              {{ amt.label }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <!-- 服務店家 -->
            <div class="col-12">
              <div class="form-group row">
                <label class="col-3 col-form-label col-form-label-lg">服務店家</label>
                <div class="col-9">
                  <select class="custom-select custom-select-lg mb-1" v-model="model.storeId" @change="changeStore">
                    <option :value="null">請選擇服務店家</option>
                    <option v-for="(item, index) in storeList" :value="item.storeId" :key="index">
                      {{ item.storeName }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <!-- 服務人員 -->
            <div class="col-12">
              <div class="form-group row">
                <label class="col-3 col-form-label col-form-label-lg">服務人員</label>
                <div class="col-9">
                  <select class="custom-select custom-select-lg mb-3" v-model="model.storeStaff">
                    <option :value="null">請選擇體驗卷服務人員</option>
                    <option v-for="(item, index) in storeStaffList" :value="item.staffName" :key="index">
                      {{ item.staffName }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <!-- 按鈕區塊 -->
            <div class="mt-4">
              <Button :label="'立即購買'" class="mx-2 mt-2 btn-md" :btnColor="'green'" @click="buy"></Button>
              <Button :label="'放購物車'" class="mx-2 mt-2 btn-md" :btnColor="'blue'" @click="cart"></Button>
              <Button v-show="!isLogin" :label="'分享好友'" class="mx-2 mt-2 btn-md" @click="goLogin"></Button>
              <ShareFriend v-if="isLogin" :currentUrl="url"></ShareFriend>
            </div>
          </div>
        </div>
      </div>
      <!-- 內容 -->
      <div class="col-12">
        <div style="text-align: center" :key="contentKey">
          <div v-for="(item, index) in mediaList" :key="index">
            <img v-show="item.imageUrl" class="card-img-top mx-auto d-block" :src="item.imageUrl" />
            <youtube v-if="item.videoId" :video-id="item.videoId" width="100%"></youtube>
          </div>
        </div>
      </div>
    </Content>
  </article>
</template>

<script>
import InputText from "@/components/style/InputText"
import Button from "@/components/style/Button"
import ShareFriend from "@/components/style/ShareFriend"
import Content from "@/components/style/Content"

export default {
  name: "Product",
  metaInfo() {
    return {
      title: this.model.title,
    }
  },
  components: {
    InputText,
    Button,
    ShareFriend,
    Content,
  },
  data() {
    return {
      current: null,
      productType: null,
      productTypeList: [],
      productNumber: null,
      storeList: [],
      amountList: [],
      storeStaffList: [],
      model: {
        title: null,
        price: 0,
        fare: 0,
        amount: null,
        storeId: null,
        storeStaff: null,
        ticketList: [],
      },
      contentKey: 0,
      isSmallSize: false,
      mediaList: [],
      imgSrc: null,
    }
  },
  methods: {
    buy() {
      let obj = this.examine()
      if (!(obj instanceof Object)) {
        return this.$Swal.fire({ icon: "error", title: "異常訊息", text: obj })
      }

      if (!this.isLogin) {
        this.$store.dispatch("storeOrderList", obj)
        this.$store.dispatch("checkLogin", "/cart")
      } else {
        this.$store.dispatch("storeOrderList", obj)
        this.$router.push({ path: "/cart" })
      }
    },
    cart() {
      let obj = this.examine()
      if (!(obj instanceof Object)) {
        return this.$Swal.fire({ icon: "error", title: "異常訊息", text: obj })
      }

      this.$store.dispatch("storeOrderList", obj)
      let swalMessage = this.current != "item5" ? `<br>數量：${this.model.amount}` : ""
      this.$Swal.fire({
        icon: "success",
        title: "成功訊息",
        html: `商品：${this.model.title}${swalMessage}<br>加入購物車成功`,
        timer: 1500,
      })
    },
    examine() {
      if (!this.model.storeId) {
        return "請選擇店家"
      } else if (!this.model.storeStaff) {
        return "請選擇服務人員"
      }

      if (this.current == "item5") {
        if (this.model.ticketList.every(item => item.amount == null)) {
          return "請選擇至少一項票卷"
        }
      } else {
        if (!this.model.amount) {
          return "請輸入數量"
        }
      }

      let obj = {}
      obj.productNumber = this.productNumber
      obj.productName = this.model.title
      obj.productPrice = this.model.price
      obj.productFare = this.model.fare
      obj.amount = parseInt(this.model.amount)
      obj.storeId = this.model.storeId
      obj.storeStaff = this.model.storeStaff
      obj.ticketList = this.current == "item5" ? this.model.ticketList : []
      obj.ticketList.forEach(item => {
        item.amount = !item.amount ? 0 : parseInt(item.amount)
      })
      return obj
    },
    async changeItem(productType) {
      try {
        this.current = this.$route.params.item
        //remove youtube iframe
        this.contentKey++

        this.model.amount = 1
        this.model.storeId = null
        this.model.storeStaff = null
        this.model.ticketList.forEach(item => {
          item.amount = 1
        })

        if (this.current == "item1") {
          this.productNumber = "pd001"
          if (!productType) {
            this.productType = "0"
            this.productTypeList = [
              { label: "黑", value: "0" },
              { label: "黑租一個月", value: "1" },
              { label: "黑租兩個月", value: "6" },
              { label: "租一個月轉買黑", value: "2" },
              { label: "能量加強版米白", value: "3" },
              { label: "租一個月轉買能量加強米白", value: "5" },
              { label: "租兩個月轉買黑", value: "7" },
              { label: "租兩個月轉買能量加強版米白", value: "8" },
            ]
          } else if (productType && productType != "0") {
            this.productNumber += `-${productType}`
          }
        } else if (this.current == "item3") {
          if (!productType) {
            this.productType = "1"
            this.productNumber = "pd003-1"
          } else {
            this.productNumber = `pd003-${productType}`
          }
          this.productTypeList = [
            { label: "租一個月", value: "1" },
            { label: "租兩個月", value: "2" },
          ]
        } else {
          let str = this.current.substring(4)
          str = str.length >= 3 ? str : new Array(3 - str.length + 1).join("0") + str
          this.productNumber = `pd${str}`
        }

        let response = await this.$http.get(`/product/search/${this.productNumber}`)
        if (response.data.errorMsg) {
          return this.$Swal.fire({ icon: "error", title: "異常訊息", text: `${response.data.errorMsg}` })
        }

        this.model.title = response.data.model.productName
        this.model.price = response.data.model.productPrice
        this.model.fare = response.data.model.productFare

        this.mediaList = _.sortBy(
          response.data.mediaList.filter(item => item.sortNo > 1),
          [
            o => {
              return parseInt(o.sortNo)
            },
          ]
        )
        this.mediaList.forEach(item => {
          item.imageUrl = null
          if (!item.videoId) {
            let imageUrl = item.blobUrl
            if (this.isSmallSize && item.blobUrlSmall) {
              imageUrl = item.blobUrlSmall
            }
            item.imageUrl = imageUrl
          }
        })
        let headObj = response.data.mediaList.find(item => item.sortNo == 1)
        this.imgSrc = this.isSmallSize ? headObj.blobUrlSmall || headObj.blobUrl : headObj.blobUrl
      } catch (error) {
        return this.$Swal.fire({ icon: "error", title: "系統發生錯誤", text: String(error) })
      }
    },
    changeProductType() {
      this.changeItem(this.productType)
    },
    goLogin() {
      if (!this.isLogin) {
        this.$store.dispatch("checkLogin")
        return
      }
    },
    async changeStore() {
      if (!this.model.storeId) {
        return
      }

      this.storeStaff = null
      let response = await this.$http.get(`/store/getStockStaff/${this.model.storeId}`)
      if (response.data.errorMsg) {
        return this.$Swal.fire({ icon: "error", title: "異常訊息", text: `${response.data.errorMsg}` })
      }
      this.storeStaffList = response.data
    },
  },
  async mounted() {
    try {
      this.isSmallSize = $(window).width() < 992

      this.model.ticketList = this.constTicketList
        .filter(item => item.productNumber.startsWith("pd005"))
        .filter(item => item.use)
        .map(item => {
          const { use, ...rest } = item
          return {
            ...rest,
            amount: null,
          }
        })

      this.changeItem()

      for (let i = 0; i <= 50; i++) {
        if (i == 0) {
          this.amountList.push({ label: "請選擇數量", value: null })
        } else {
          this.amountList.push({ label: `${i}`, value: i })
        }
      }

      let response = await this.$http.get("/store/searchList?enable=Y")
      if (response.data.errorMsg) {
        return this.$Swal.fire({ icon: "error", title: "異常訊息", text: `${response.data.errorMsg}` })
      }

      this.storeList = response.data
    } catch (error) {
      return this.$Swal.fire({ icon: "error", title: "系統發生錯誤", text: String(error) })
    }
  },
  computed: {
    url() {
      return `${location.protocol}//${location.host}${this.$route.path}`
    },
    isLogin() {
      return this.$store.getters.isLogin()
    },
    showAgent() {
      return this.current != "item5" && !this.constMemberList.some(str => str == this.current)
    },
    isMember() {
      return this.constMemberList.some(str => str == this.current)
    },
  },
  watch: {
    $route() {
      this.changeItem()
    },
  },
}
</script>

<style scoped>
.card-body,
.card-header {
  text-align: center;
}
.card-header h3 {
  margin: 0;
  color: crimson;
}
.list-group-item .row div:first-child {
  font-size: 1.5rem;
}
.col-form-label-lg,
.custom-select-lg {
  font-size: 1.2rem;
}
.form-group {
  margin-bottom: 0.7rem;
}
@media (min-width: 992px) {
  .product-container {
    margin: 0 5%;
    width: 90%;
  }
}
@media (min-width: 320px) and (max-width: 575px) {
  div[class*="col-"]:not(:last-of-type) {
    margin-bottom: 10px !important;
  }
}
</style>
