<template>
  <article>
    <Content>
      <div class="col-12">
        <div class="card-header">
          <div class="alert alert-info" role="alert">點數</div>
        </div>
        <!-- 目前可領取點數 -->
        <div class="alert alert-success" role="alert" style="margin-bottom: 0">
          點數清單
          <div v-show="isAgent" class="row">
            <div class="col-8 col-sm-10 text-right">目前可領取點數</div>
            <div class="col-4 col-sm-2 text-left">{{ currentTotalCanApplyBonus }}</div>
          </div>
        </div>
        <!-- 篩選獎金明細月份 -->
        <div v-show="currentMonth != null" class="alert alert-light" role="alert" style="margin-bottom: 0">
          <div class="row">
            <div class="col-3">篩選點數月份</div>
            <div class="col-9">
              <select class="custom-select custom-select-lg" v-model="currentMonth" @change="filterBonusHistory">
                <option v-for="(item, index) in bonusHistoryAllList" :value="item.month" :key="index">
                  {{ item.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <!-- 獎金明細清單，特專跟VIP都能看到 -->
        <div style="height: 400px; overflow-y: auto">
          <table class="table table-hover" style="width: 1100px">
            <thead style="font-weight: bold">
              <tr>
                <th scope="col">客戶之LINE名稱</th>
                <th scope="col">點數日期</th>
                <th scope="col">點數來源</th>
                <th scope="col">推廣點數</th>
                <th scope="col">介紹點數</th>
                <th scope="col">提領點數</th>
              </tr>
            </thead>
            <tbody v-if="bonusHistoryList.length > 0">
              <tr v-for="(item, index) in bonusHistoryList" :key="index">
                <td>{{ item.lineName }}</td>
                <td>{{ item.bonusDate }}</td>
                <td>{{ item.productCategory }}</td>
                <td>{{ item.firstBonus }}</td>
                <td>{{ item.secondBonus }}</td>
                <td>{{ item.totalBonus }}</td>
              </tr>
            </tbody>
            <tbody v-else>
              <td colspan="7">無點數清單</td>
            </tbody>
          </table>
        </div>
        <!-- 獎金清單，只有特專才能看到 -->
        <div v-show="isAgent">
          <div class="alert alert-warning" role="alert" style="margin-bottom: 0">提領點數清單</div>
          <div style="height: 400px; overflow-y: auto">
            <table class="table table-hover" style="width: 1100px">
              <thead style="font-weight: bold">
                <tr>
                  <th scope="col">申請日期</th>
                  <th scope="col">提領點數</th>
                  <th scope="col">銀行代號</th>
                  <th scope="col">銀行帳號</th>
                  <th scope="col">提領狀態</th>
                </tr>
              </thead>
              <tbody v-if="applyFilterList.length > 0">
                <tr v-for="(item, index) in applyFilterList" :key="index">
                  <td>{{ item.applyDate }}</td>
                  <td>{{ item.applyPrice }}</td>
                  <td>{{ item.bankCode }}</td>
                  <td>{{ item.bankAccount }}</td>
                  <td v-if="item.cancelStatus != 'Y'">
                    <div>{{ item.flag }}</div>
                    <div v-if="item.showConfirmBtn">
                      <Button :label="'確認提領'" :btnColor="'yellow'" @click="confirmApplied(item)"></Button>
                    </div>
                  </td>
                  <td v-else>
                    {{ "取消原因：" + item.cancelReason }}
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <td colspan="5">無提領點數清單</td>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Content>
  </article>
</template>

<script>
import InputText from "@/components/style/InputText"
import Button from "@/components/style/Button"
import Content from "@/components/style/Content"

import moment from "moment"
export default {
  name: "Bonus",
  data() {
    return {
      currentTotalBonus: 0,
      currentTotalCanApplyBonus: 0,
      currentMonth: null,
      bonusHistoryAllList: [],
      bonusHistoryList: [],
      applyList: [],
      applyFilterList: [],
    }
  },
  components: {
    InputText,
    Button,
    Content,
  },
  methods: {
    checkFormat(e) {
      if (e.target.value.length > 0) {
        let value = String(e.target.value)
        let regExp = /^[\d]+$/
        if (!regExp.test(value) || parseInt(value) < 0) {
          e.target.value = null
        } else {
          e.target.value = String(parseInt(e.target.value))
        }
      }
      return e.target.value
    },
    async confirmApplied(item) {
      if (this.realConfirm != "Y") {
        let html = "尚未通過實名認證<br>請輸入身分證號碼，並出示身份證以實名認證<br>若公司法人請提供發票，品項為傭金收入，發票抬頭及統編為提領店家"
        return this.$Swal.fire({ icon: "error", title: "異常訊息", html })
      }

      let confirmed = await this.$Swal.fire({
        icon: "warning",
        title: "再次確認",
        text: `是否確認領取${item.flag.substring(0, 9)}?`,
        showCancelButton: true,
        confirmButtonText: "確認領取",
        cancelButtonText: "取消",
      })
      if (!confirmed.value) {
        return
      }

      let response = await this.$http.post(`/bonus/confirmApplied/${item.id}`)
      if (response.data.errorMsg) {
        return this.$Swal.fire({ icon: "error", title: "異常訊息", text: `${response.data.errorMsg}` })
      }

      await this.$Swal.fire({ icon: "success", title: "成功訊息", text: "領取成功" })
      this.reload()
    },
    filterBonusHistory() {
      this.bonusHistoryList = this.bonusHistoryAllList.find(item => item.month == this.currentMonth).bonusHistoryList
    },
  },
  computed: {
    isLogin() {
      return this.$store.getters.isLogin()
    },
    realConfirm() {
      return this.$store.state.userInfo.userProfile.realConfirm
    },
    isAgent() {
      return this.$store.state.userInfo.userProfile.agent == "Y"
    },
  },
  async mounted() {
    try {
      if (!this.isLogin) {
        this.$store.dispatch("checkLogin")
        return
      }

      this.$store.commit("SET_LOADING", true)
      let promise1 = this.$http.get("/bonus/searchBonusHistoryListFromFront")
      let promise2 = this.$http.get("/bonus/searchList")
      let promiseArr = await Promise.all([promise1, promise2])
      this.$store.commit("SET_LOADING", false)

      for (let i = 0; i < 2; i++) {
        let result = promiseArr[i].data
        if (result.errorMsg) {
          return this.$Swal.fire({ icon: "error", title: "異常訊息", text: `${result.errorMsg}` })
        }

        if (i == 0) {
          //點數清單
          let monthMap = new Map()
          result.forEach(element => {
            let totalBonus = element.firstBonus + element.secondBonus
            element.totalBonus = totalBonus
            this.currentTotalBonus += totalBonus

            element.bonusDate = moment(element.bonusDate).format("YYYY-MM-DD")
            element.month = moment(element.bonusDate).format("YYYY-MM")
            if (!monthMap.has(element.month)) {
              monthMap.set(element.month, new Array())
              this.bonusHistoryAllList.push({
                month: element.month,
                name: `${element.month.substring(0, 4)}年${element.month.substring(5)}月`,
              })
            }
            let monthArr = monthMap.get(element.month)
            monthArr.push(element)
            monthMap.set(element.month, monthArr)
          })

          for (let [month, monthArr] of monthMap.entries()) {
            this.bonusHistoryAllList.forEach(bonusObj => {
              if (bonusObj.month == month) {
                bonusObj.bonusHistoryList = monthArr
              }
            })
          }

          if (result.length > 0) {
            this.bonusHistoryAllList = _.orderBy(this.bonusHistoryAllList, ["month"], ["desc"])
            this.bonusHistoryAllList.forEach(bonusObj => {
              bonusObj.bonusHistoryList = _.orderBy(bonusObj.bonusHistoryList, ["bonusDate"], ["desc"])
            })
            this.bonusHistoryList = this.bonusHistoryAllList[0].bonusHistoryList
            this.currentMonth = this.bonusHistoryAllList[0].month
          }
        } else {
          //提領點數清單
          this.applyList = result
          this.applyList.forEach(item => {
            item.showConfirmBtn = false
            item.showItem = false
            if (item.bankCode == "SYSTEM" || item.bankCode.startsWith("DONE")) {
              item.flag = item.bankCode == "SYSTEM" ? `${item.bankAccount}點數請跟店家領取` : `已領取，日期：${item.bankCode.substring(4)}`
              item.showConfirmBtn = item.bankCode == "SYSTEM"
              item.showItem = item.showConfirmBtn
              item.bankCode = ""
              item.bankAccount = ""
            } else {
              item.flag = !item.remitDate ? "尚未匯款" : "已匯款，日期：" + item.remitDate
              item.showItem = !item.remitDate
            }
          })

          //前端顯示:尚未領取的提領點數清單
          this.applyFilterList = this.applyList.filter(item => item.showItem)

          //前端顯示:目前可領取點數
          let totalAppliedPrice = 0
          this.applyList.forEach(item => {
            if (item.remitDate || item.cancelStatus != "Y") {
              totalAppliedPrice += item.applyPrice
            }
          })
          this.currentTotalCanApplyBonus = this.currentTotalBonus - totalAppliedPrice
        }
      }
    } catch (error) {
      this.$store.commit("SET_LOADING", false)
      return this.$Swal.fire({ icon: "error", title: "系統發生錯誤", text: String(error) })
    }
  },
  inject: ["reload"],
}
</script>

<style scoped>
.card-header {
  text-align: center;
  padding: 0;
}
.card-header div {
  margin-bottom: 0;
  font-size: 1.5rem;
  font-weight: bold;
}
thead,
tbody,
.alert {
  text-align: center;
  font-size: 1.5rem;
}
</style>
