<template>
  <section class="ptb-100">
    <div class="container">
      <div class="card border-primary">
        <div class="row no-gutters">
          <slot></slot>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Content",
}
</script>

<style>
.container {
  padding-left: 0;
  padding-right: 0;
}
</style>
