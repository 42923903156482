<template>
  <button type="button" class="btn beauty-btn" :style="btnStyle" :class="btnClass" @click="click">
    <slot></slot>{{ label }}
  </button>
</template>

<script>
export default {
  name: "Button",
  props: ["btnColor", "label", "btnClass"],
  computed: {
    btnStyle() {
      let bgColor = "#6730e3"
      let fontColorStyle = ""
      if (this.btnColor == "blue") {
        bgColor = "#007bff"
      } else if (this.btnColor == "green") {
        bgColor = "#28a745"
      } else if (this.btnColor == "red") {
        bgColor = "#dc3545"
      } else if (this.btnColor == "yellow") {
        bgColor = "#ffc107"
        fontColorStyle = ";color:black"
      } else if (this.btnColor == "coffee") {
        bgColor = "rgb(154, 132, 80)"
      }
      return `background:${bgColor};border-color:${bgColor}${fontColorStyle}`
    },
  },
  methods: {
    click() {
      this.$emit("click")
    },
  },
}
</script>

<style scoped></style>
