import Vue from "vue"
import Router from "vue-router"
import VueMeta from "vue-meta"
import Cookies from "js-cookie"
import store from "@/config/store"
import Home from "@/components/Home"
import Advertisement from "@/components/Advertisement"
import Product from "@/components/Product"
import User from "@/components/User"
import Order from "@/components/Order"
import Cart from "@/components/Cart"
import Bonus from "@/components/Bonus"
import Location from "@/components/Location"

Vue.use(Router)
Vue.use(VueMeta)

const setParentUserId = parentUserId => {
  Cookies.set("parentUserId", parentUserId, { expires: 7 })

  localStorage.setItem("parentUserId", parentUserId)
  localStorage.setItem("expireTime", String(new Date().getTime() + 86400 * 7))
}

const getParentUserId = () => {
  let parentUser = "root"
  if (Cookies.get("parentUserId")) {
    parentUser = Cookies.get("parentUserId")
  } else if (localStorage.getItem("parentUserId")) {
    let currentTime = new Date().getTime()
    if (parseInt(localStorage.getItem("expireTime")) < currentTime) {
      localStorage.clear()
    } else {
      parentUser = localStorage.getItem("parentUserId")
    }
  }
  return parentUser
}

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "Home",
      component: Home,
      beforeEnter(to, from, next) {
        let url = new URL(window.location.href)
        let token = url.searchParams.get("token")
        if (token) {
          store.dispatch("loginCallback", token)
        } else {
          next()
        }
      },
    },
    {
      path: "/home",
      redirect: "/",
    },
    {
      path: "/advertisement",
      name: "Advertisement",
      component: Advertisement,
    },
    {
      path: "/advertisement/:parentUserId",
      name: "AdvertisementByParentUserId",
      component: Advertisement,
      beforeEnter(to, from, next) {
        let parentUserId = to.params.parentUserId
        if (parentUserId) {
          setParentUserId(parentUserId)
        }
        next({ name: "Advertisement", replace: true })
      },
    },
    {
      path: "/advertisement/item1",
      name: "AdvertisementItem1",
      component: Advertisement,
      beforeEnter(to, from, next) {
        next({ name: "Advertisement", replace: true })
      },
    },
    {
      path: "/advertisement/item1/:parentUserId",
      name: "AdvertisementItem1ByParentUserId",
      component: Advertisement,
      beforeEnter(to, from, next) {
        let parentUserId = to.params.parentUserId
        if (parentUserId) {
          setParentUserId(parentUserId)
        }
        next({ name: "Advertisement", replace: true })
      },
    },
    {
      path: "/product/:item",
      name: "Product",
      component: Product,
    },
    {
      path: "/product/:item/:parentUserId",
      name: "ProductByParentUserId",
      component: Product,
      beforeEnter(to, from, next) {
        let parentUserId = to.params.parentUserId
        if (parentUserId) {
          setParentUserId(parentUserId)
        }
        next({ name: "Product", params: { item: to.params.item }, replace: true })
      },
    },
    {
      path: "/user",
      name: "User",
      component: User,
    },
    {
      path: "/user/:userId/:showContract",
      name: "UserByShowContract",
      component: User,
      beforeEnter(to, from, next) {
        let showContract = to.params.showContract
        if (showContract && showContract == "sign") {
          Cookies.set("showContract", to.params.userId, { expires: 1 })
        }
        next({ name: "User", replace: true })
      },
    },
    {
      path: "/order",
      name: "Order",
      component: Order,
    },
    {
      path: "/cart",
      name: "Cart",
      component: Cart,
    },
    {
      path: "/bonus",
      name: "Bonus",
      component: Bonus,
    },
    {
      path: "/location",
      name: "Location",
      component: Location,
    },
    {
      path: "/line-login",
      beforeEnter(to, from, next) {
        window.location.href = `${to.query.lineUrl}${getParentUserId()}`
      },
    },
    {
      path: "/google-doc1",
      beforeEnter() {
        window.location.href = "https://docs.google.com/forms/d/e/1FAIpQLSeie5iIygUMmGM4EhAq7mmCDGZuC_bVKB0A9wioDLUGHx4rAg/viewform?usp=sf_link"
      },
    },
    {
      path: "/google-doc2",
      beforeEnter() {
        window.location.href = "https://docs.google.com/forms/d/e/1FAIpQLSfX6Hvadkcr0th6DZNVtqU2mqOm-IIlnja9hMUP1QQkyklRnA/viewform?usp=sf_link"
      },
    },
    {
      path: "/weebly1",
      beforeEnter() {
        window.location.href = "https://beatydr.weebly.com/25307212152930523560.html"
      },
    },
    {
      path: "/weebly2",
      beforeEnter() {
        window.location.href = "https://beatydr.weebly.com"
      },
    },
  ],
})
router.beforeEach((to, from, next) => {
  let url = new URL(window.location.href)
  let liff_state = url.searchParams.get("liff.state")
  if (liff_state) {
    let parentUserId = liff_state.replace("/", "")
    setParentUserId(parentUserId)
  }
  $("html, body").animate({ scrollTop: 0 }, "slow")
  next()
})

export default router
