jQuery(function($) {
  "use strict"

  $().UItoTop({ easingType: "easeOutQuart" })

  $(".navbar-nav li a").on("click", function(event) {
    if (
      !$(this)
        .parent()
        .hasClass("dropdown")
    )
      $(".navbar-collapse").collapse("hide")
  })

  $(".screen-carousel").owlCarousel({
    loop: true,
    margin: 0,
    center: true,
    dots: true,
    nav: false,
    autoplay: true,
    responsive: {
      0: {
        items: 1,
      },
      768: {
        items: 3,
      },
      991: {
        items: 4,
      },
      1200: {
        items: 4,
      },
      1920: {
        items: 4,
      },
    },
  })

  var isFirstTime = true
  var interval = null
  var countSelector = $(".single-counter > span, .single-card > h3")
  if (countSelector.length) {
    var startingTop = countSelector.offset().top - window.innerHeight
    if (startingTop > 0) {
      $(window).on("scroll", function() {
        if (isFirstTime && $(window).scrollTop() > startingTop) {
          startCounting()
          isFirstTime = false
        }
      })
    } else {
      startCounting()
    }
  }

  /**
   * Get the increment value
   * @param value
   * @returns {number}
   */
  function incrementValue(value) {
    var incVal = 0
    if (Math.ceil(value / 2) <= 5) {
      // upto 10
      incVal = 1
    } else if (Math.ceil(value / 10) <= 10) {
      // up to 100
      incVal = 10
    } else if (Math.ceil(value / 100) <= 10) {
      // up to 1000
      incVal = 25
    } else if (Math.ceil(value / 100) <= 100) {
      // up to 10000
      incVal = 50
    } else if (Math.ceil(value / 1000) <= 100) {
      // up to 100000
      incVal = 150
    } else {
      incVal = 500
    }
    return incVal
  }

  /**
   * To start count
   * @param counters all selectors
   * @param start int
   * @param value int
   * @param id int
   */
  function count(counters, start, value, id) {
    var localStart = start
    var inc = incrementValue(value)
    interval = setInterval(function() {
      if (localStart < value) {
        localStart = localStart + inc
        counters[id].innerHTML = localStart
      }
    }, 40)
  }

  /**
   * Start the count
   */
  function startCounting() {
    var counters = $(".single-counter > span, .single-card > h3")
    var countersQuantity = counters.length
    var counter = []

    // get al counts from HTML count
    for (var i = 0; i < countersQuantity; i++) {
      counter[i] = parseInt(counters[i].innerHTML)
    }

    // calling all count function
    for (var j = 0; j < countersQuantity; j++) {
      count(counters, 0, counter[j], j)
    }
  }
})
