<template>
  <div id="app" v-cloak>
    <Header></Header>
    <router-view :key="componentKey" />
    <Footer></Footer>
    <BottomIcon></BottomIcon>
    <Loading v-if="isLoading"></Loading>
  </div>
</template>

<script>
import Header from "@/components/Header"
import Footer from "@/components/Footer"
import BottomIcon from "@/components/style/BottomIcon"
import Loading from "@/components/style/Loading"

export default {
  name: "App",
  metaInfo: {
    title: "地表最強能量養生",
    titleTemplate: "氣能康富 | %s",
  },
  components: {
    Header,
    Footer,
    BottomIcon,
    Loading,
  },
  data() {
    return {
      componentKey: 0,
    }
  },
  methods: {
    reload() {
      this.componentKey++
    },
  },
  computed: {
    isLoading() {
      return this.$store.state.loading
    },
  },
  mounted() {
    document.dispatchEvent(new Event("render-event"))
  },
  provide() {
    return {
      reload: this.reload,
    }
  },
}
</script>
