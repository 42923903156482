<template>
  <article>
    <Content>
      <div class="col-12">
        <div class="card-header">
          <div class="alert alert-info" role="alert">訂單查詢</div>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <div id="orderList" class="accordion accordion-faq">
                <div class="card" v-for="(item, index) in orderList" :key="index">
                  <div
                    class="card-header py-4"
                    data-toggle="collapse"
                    role="button"
                    :aria-expanded="index == 0"
                    :id="'heading' + index"
                    :data-target="'#collapse' + index">
                    <div class="row">
                      <div class="col align-self-start">
                        <h5 class="mb-0">訂單號碼：{{ item.orderNumber }}</h5>
                      </div>
                      <div class="col align-self-end">
                        <h5 class="mb-0">訂單狀態：{{ item.paymentInfo ? "已付款" : "尚未付款" }}</h5>
                      </div>
                    </div>
                  </div>
                  <div
                    class="collapse"
                    :class="index == 0 ? 'show' : ''"
                    data-parent="#orderList"
                    :id="'collapse' + index"
                    :aria-labelledby="'heading' + index">
                    <div class="card-body">
                      <div class="row" style="text-align: left; margin-left: 0; margin-right: 0">
                        <div class="col-md-6 com-sm-12">
                          訂單時間：{{ item.orderDate }}<br />
                          訂單金額：{{ item.orderPrice }}
                        </div>
                        <div class="col-md-6 com-sm-12">
                          訂單狀態：{{ item.paymentInfo ? "已付款" : "尚未付款" }}
                          <span style="color: #0c5460; margin-left: 1rem">
                            {{ item.paymentInfo ? "" : "點下方賴詢問 氣能康富收款帳號" }}
                          </span>
                          <br />付款方式：{{ item.paymentTypeName }}
                        </div>
                        <div class="col-md-6 com-sm-12">
                          收件人姓名：{{ item.receiverName }}<br />
                          收件人電話：{{ item.receiverPhone }}
                        </div>
                        <div class="col-md-6 com-sm-12">
                          收件人地址：{{ item.receiverAddress }}<br />
                          備註：{{ item.remark }}
                        </div>
                        <div class="col-12">
                          <div class="my-2" style="text-align: center">
                            <Button :label="'修改訂單資訊'" :btnColor="'yellow'" class="mx-1" @click="update(item.orderNumber)"></Button>
                            <Button :label="'查看訂單明細'" class="mx-1" @click="detail(item.orderNumber)"></Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Content>
    <!-- 修改訂單資訊 -->
    <Modal :id="'modal_orderInfo'">
      <span slot="header">修改訂單資訊</span>
      <span slot="footer">
        <button type="button" class="btn btn-lg btn-danger" @click="cancel" :disabled="loading">取消訂單</button>
        <button type="button" class="btn btn-lg btn-warning" @click="submit" :disabled="loading">確認修改</button>
      </span>
      <div class="col-12">
        <!--    <div class="form-group row">
          <label class="col-5 col-form-label col-form-label-lg"
            >付款方式：</label
          >
          <div class="col-7">
            <select
              class="custom-select custom-select-lg"
              v-model="model.paymentType"
              :disabled="model.paymentInfo"
            >
              <option
                v-for="(item, index) in paymentTypeList"
                :value="item.value"
                :key="index"
              >
                {{ item.label }}
              </option>
            </select>
          </div>
        </div> -->
        <div class="form-group row">
          <label class="col-5 col-form-label col-form-label-lg">收件人姓名：</label>
          <div class="col-7">
            <InputText v-model="model.receiverName" />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-5 col-form-label col-form-label-lg">收件人電話：</label>
          <div class="col-7">
            <InputText v-model="model.receiverPhone" />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-5 col-form-label col-form-label-lg">收件人地址：</label>
          <div class="col-7">
            <InputText v-model="model.receiverAddress" />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-5 col-form-label col-form-label-lg">備註：</label>
          <div class="col-7">
            <InputText v-model="model.remark" />
          </div>
        </div>
      </div>
    </Modal>
    <!-- 訂單明細 -->
    <Modal :id="'modal_orderDetail'">
      <span slot="header">訂單明細</span>
      <table class="table table-hover">
        <thead>
          <tr>
            <th scope="col">細項號碼</th>
            <th scope="col">商品名稱</th>
            <th scope="col">數量</th>
            <th scope="col">出貨狀態</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in orderDetailList" :key="index">
            <td>{{ item.detailNumber }}</td>
            <td style="width: 30%; word-break: break-all">
              {{ item.productName }}
            </td>
            <td>
              {{ item.productNumber == "pd005" ? "" : item.detailAmount }}
            </td>
            <td>{{ item.shippedStatus }}</td>
          </tr>
        </tbody>
      </table>
    </Modal>
  </article>
</template>

<script>
import InputText from "@/components/style/InputText"
import Button from "@/components/style/Button"
import Modal from "@/components/style/Modal"
import Content from "@/components/style/Content"

import moment from "moment"
export default {
  name: "Order",
  components: {
    InputText,
    Button,
    Modal,
    Content,
  },
  data() {
    return {
      orderList: [],
      model: {},
      orderDetailList: [],
      loading: false,
    }
  },
  methods: {
    update(orderNumber) {
      this.model = {}
      this.orderList.forEach(item => {
        if (item.orderNumber == orderNumber) {
          this.model = Object.assign({}, item)
        }
      })
      $("#modal_orderInfo").modal("show")
    },
    async detail(orderNumber) {
      try {
        let response = await this.$http.get(`/order/searchDetailList/${orderNumber}`)
        if (response.data.errorMsg) {
          return this.$Swal.fire({ icon: "error", title: "異常訊息", text: `${response.data.errorMsg}` })
        }

        this.orderDetailList = response.data
        $("#modal_orderDetail").modal("show")
      } catch (error) {
        return this.$Swal.fire({ icon: "error", title: "系統發生錯誤", text: String(error) })
      }
    },
    async submit() {
      let errorMessage = ""
      if (!this.model.paymentType) {
        errorMessage += "請選擇付款方式<br>"
      }
      if (!this.model.receiverName) {
        errorMessage += "請輸入收件人姓名<br>"
      }
      if (!this.model.receiverPhone) {
        errorMessage += "請輸入收件人電話<br>"
      }
      if (!this.model.receiverAddress) {
        errorMessage += "請輸入收件人地址<br>"
      }
      if (errorMessage) {
        return this.$Swal.fire({ icon: "error", title: "異常訊息", html: `${errorMessage}` })
      }

      try {
        this.loading = true
        let response = await this.$http.post(`/order/updateOrder/${this.model.orderNumber}`, this.model)
        this.loading = false
        if (response.data.errorMsg) {
          return this.$Swal.fire({ icon: "error", title: "異常訊息", text: `${response.data.errorMsg}` })
        }

        await this.$Swal.fire({ icon: "success", title: "成功訊息", text: `訂單編號：${this.model.orderNumber},修改成功` })
        this.$nextTick(() => {
          this.orderList.forEach(item => {
            if (item.orderNumber == this.model.orderNumber) {
              Object.assign(item, this.model)
              item.paymentTypeName = this.paymentTypeList.find(paymentType => paymentType.value == item.paymentType).label
            }
          })
          $("#modal_orderInfo").modal("hide")
        })
      } catch (error) {
        this.loading = false
        return this.$Swal.fire({ icon: "error", title: "系統發生錯誤", text: String(error) })
      }
    },
    async cancel() {
      try {
        let confirmed = await this.$Swal.fire({
          icon: "warning",
          title: "再次確認",
          text: `訂單編號：${this.model.orderNumber},是否取消?`,
          showCancelButton: true,
          confirmButtonText: "確認",
          cancelButtonText: "放棄",
        })
        if (!confirmed.value) {
          return
        }

        this.loading = true
        let response = await this.$http.post(`/order/cancelOrder/${this.model.orderNumber}`)
        this.loading = false
        if (response.data.errorMsg) {
          return this.$Swal.fire({ icon: "error", title: "異常訊息", text: `${response.data.errorMsg}` })
        }

        await this.$Swal.fire({ icon: "success", title: "成功訊息", text: `訂單編號：${this.model.orderNumber},已成功取消訂單` })
        this.$nextTick(() => {
          this.reload()
          $("#modal_orderInfo").modal("hide")
        })
      } catch (error) {
        this.loading = false
        return this.$Swal.fire({ icon: "error", title: "系統發生錯誤", text: String(error) })
      }
    },
  },
  async mounted() {
    try {
      if (!this.isLogin) {
        this.$store.dispatch("checkLogin")
        return
      }

      let response = await this.$http.get("/order/searchList")
      if (response.data.errorMsg) {
        return this.$Swal.fire({ icon: "error", title: "異常訊息", text: `${response.data.errorMsg}` })
      }
      if (response.data.length == 0) {
        return this.$Swal.fire({ icon: "info", title: "訊息", text: "查無訂單資料" })
      }

      this.orderList = response.data
      this.orderList.forEach(item => {
        item.orderDate = moment(item.orderDate).format("YYYY-MM-DD HH:mm:ss")
        item.paymentTypeName = this.paymentTypeList.find(paymentType => paymentType.value == item.paymentType).label
      })
    } catch (error) {
      return this.$Swal.fire({ icon: "error", title: "系統發生錯誤", text: String(error) })
    }
  },
  computed: {
    isLogin() {
      return this.$store.getters.isLogin()
    },
  },
  inject: ["reload"],
}
</script>

<style scoped>
.card-header,
.card-body {
  text-align: center;
  padding: 0;
}
.card-header div {
  margin-bottom: 0;
  font-size: 1.5rem;
  font-weight: bold;
}
.card-body div {
  font-size: 1.5rem;
}
div[data-toggle="collapse"] {
  text-align: left;
  padding-left: 1rem;
}
</style>
