<template>
  <input type="text" class="form-control input" :value="value" @input="input" />
</template>

<script>
export default {
  name: "InputText",
  props: ["value"],
  methods: {
    input(event) {
      this.$emit("input", event.target.value)
    },
  },
}
</script>

<style scoped>
input {
  background: #fdfcfb;
  font-family: inherit;
  color: #737373;
  letter-spacing: 1px;
  text-indent: 5%;
  border-radius: 30px;
}
</style>
