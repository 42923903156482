<template>
  <div class="custom-control custom-checkbox checkbox-xl">
    <input type="checkbox" class="custom-control-input" :id="id" @change="change" :disabled="isDisabled" />
    <label class="custom-control-label" :for="id">{{ label }}</label>
  </div>
</template>

<script>
export default {
  name: "Checkbox",
  props: {
    label: String,
    id: String,
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    change(event) {
      this.$emit("input", {
        checked: event.target.checked,
      })
    },
  },
}
</script>

<style scoped>
.checkbox-xl .custom-control-label::before,
.checkbox-xl .custom-control-label::after {
  top: 1.2rem;
  width: 1.85rem;
  height: 1.85rem;
}
.checkbox-xl .custom-control-label {
  padding-top: 20px;
  padding-left: 15px;
}
</style>
